<template>
  <v-row>
    <v-col cols="12" sm="3">
      <p class="body-2 ma-0 grey--text">{{ $_strings.statusOrder.CUBIC }}</p>
      <span>{{shipment.cubic || 0}}</span>
    </v-col>
    <v-col cols="12" sm="3">
      <p class="body-2 ma-0 grey--text">{{ $_strings.statusOrder.TONNAGE }}(Kg)</p>
      <span>{{shipment.tonnage || 0}}</span>
    </v-col>
    <v-col cols="12" sm="3">
      <p class="body-2 ma-0 grey--text">{{ $_strings.statusOrder.BOX_CARTON }}</p>
      <span>{{shipment.carton || 0}}</span>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    shipment: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
