<template>
  <section>
    <h3
      class="font-weight-bold black--text mb-4"
    >
      {{$_strings.order.DOCUMENTATION}}
    </h3>
    <v-row>
      <v-col v-if="documents.length === 0">
        <p class="text-center">Belum ada dokumentasi</p>
      </v-col>
      <v-col else cols="12">
        <v-row>
          {{documentName = ''}}
          <v-col v-for="(document, indexDocument) in documents" cols="12" sm="4" :key="'doc'+indexDocument">
            <div style="height:48px;overflow:hidden;">
              <p v-if="document.documentName.localeCompare(documentName)" class="grey--text mb-0">
                {{documentName = document.documentName}}
              </p>
            </div>
            <viewer-image
              :src="document.documentUrl"
              height="200px"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { dateFormat, timeFormat } from '@/helper/commonHelpers';

export default {
  props: {
    documents: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    dateFormat,
    timeFormat,
  },
};
</script>
