<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      width="500"
      scrollable
    >
      <v-card>
        <v-card-title>
          <h4 class="headtitle-email" align="center">Detail BASP</h4>
          <v-row>
            <v-col class="d-flex justify-end">
              <v-toolbar-items>
                <v-btn
                  icon
                  color="black"
                  dark
                  @click="dialog = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar-items>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col class="pb-0" cols="12">
              <h4>{{$_strings.order.BASP_REASON}}</h4>
            </v-col>
            <v-col class="pt-0 pb-0" cols="12">
              <v-text-field
                disabled
                outlined
                class="caption"
                dense
                :value="items.baspTitle"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0 pt-0" cols="12">
              <h4>{{$_strings.order.EXPLANATION_OF_REASON}}</h4>
            </v-col>
            <v-col class="pt-0 pb-0" cols="12">
              <v-textarea
                outlined
                disabled
                class="caption"
                dense
                v-model="items.baspNote"
                rows="5"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0 pt-0" cols="12">
              <h4>{{$_strings.order.TYPE_OF_GOODS}}</h4>
            </v-col>
            <v-col class="pt-0 pb-0" cols="12">
              <v-text-field
                disabled
                outlined
                class="caption"
                dense
                :value="`${items.categoryName} - ${items.subCategoryName}`"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0 pt-0" cols="12">
              <h4>{{$_strings.order.DESCRIPTION}}</h4>
            </v-col>
            <v-col class="pt-0 pb-0" cols="12">
              <v-text-field
                disabled
                outlined
                class="caption"
                dense
                :value="items.goodsNote || '-'"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0 pt-0" cols="12">
              <h4>{{$_strings.order.PROOF_OF_DIFFERENCE_IN_ACCEPTANCE}} / {{$_strings.order.DOCUMENTATION}}</h4>
            </v-col>
            <v-col v-if="items.images && !items.images.length">
              <p>
                <i>Belum ada dokumentasi</i>
              </p>
            </v-col>
            <v-col v-for="(item, index) in  items.images" :key="index" class="pt-0" cols="6">
              <a @click="openImage(item)">
                <v-img
                  :src="item"
                  class="white--text align-end"
                  gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                  width="200px"
                  height="200px"
                >
                </v-img>
              </a>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <dialog-view-image
        :visible="dialogViewImage"
        @setDialog="setDialogByDefault"
        :imageUrl="imageSelected"
      />
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  data() {
    return {
      dialog: false,
      imageSelected: '',
      items: {},
      dialogViewImage: false,
    };
  },
  methods: {
    openImage(item) {
      const fileType = item.split('.').pop().toLowerCase();
      if (fileType === 'png' || fileType === 'jpg' || fileType === 'jpeg') {
        this.dialogViewImage = true;
        this.imageSelected = item;
        return;
      }
      this.dialogViewImage = true;
      this.imageSelected = item;
    },
    setDialogByDefault(dialog) {
      this[dialog] = !this[dialog];
    },
  },
};
</script>
