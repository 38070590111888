<template>
  <section>
    <h5 class="mt-4 mb-4">{{$_strings.order.RUTE_INFO}}</h5>
    <v-data-table
      :headers="headers"
      calculate-widths
      hide-default-footer
      :items="items"
      class="elevation-1 rounded"
    >
      <template v-slot:[`item.multiPick`]="{item}" >
        <p v-if="item.multiPick.length === 0"> - </p>
        <p v-for="(itemMultiPick, index) in item.multiPick" :key="index" class="mb-0">
          {{index + 1}} - {{itemMultiPick.locationName}}
        </p>
      </template>
      <template v-slot:[`item.multiDrop`]="{item}" >
        <p v-if="item.multiDrop.length === 0"> - </p>
        <p v-for="(itemMultiDrop, index) in item.multiDrop" :key="index" class="mb-0">
          {{index + 1}} - {{itemMultiDrop.locationName}}
        </p>
      </template>
      <template v-slot:[`item.etaDateTime`]="{item}">
        <p class="ma-0">{{dateFormat(item.etaDateTime)}}</p>
        <p v-if="item.etaDateTime">{{timeFormat(item.etaDateTime)}}</p>
      </template>
    </v-data-table>
  </section>
</template>

<script>
import { dateFormat, timeFormat } from '@/helper/commonHelpers';

export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      headers: [
        {
          text: this.$_strings.order.ORIGIN,
          value: 'originLocationName',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: this.$_strings.order.DESTINATION,
          value: 'destinationLocationName',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: this.$_strings.order.MULTIPICK,
          value: 'multiPick',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: this.$_strings.order.MULTIDROP,
          value: 'multiDrop',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: this.$_strings.order.DRIVER_NAME,
          value: 'driverName',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: this.$_strings.order.CONTACT_DRIVER,
          value: 'driverPhone',
          width: '150px',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: this.$_strings.order.NO_TRANSPORTER,
          value: 'licensePlate',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: this.$_strings.order.VEHICLE,
          value: 'transportName',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: this.$_strings.order.ETA,
          value: 'etaDateTime',
          width: '180px',
          class: 'black--text amber accent-3 text-uppercase',
          sortable: false,
        },
      ],
    };
  },
  methods: {
    dateFormat,
    timeFormat,
  },
};
</script>
