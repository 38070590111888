<template>
  <section>
    <v-row>
      <v-col cols="10">
        <p class="body-2 ma-0 grey--text">{{$_strings.statusOrder.BASP_LOCATION}}</p>
        <div class="d-flex">
          <div class="d-flex mr-2 align-center">
            <v-icon color="red accent-4">mdi-map-marker-radius</v-icon>
          </div>
          <div>
            <p class="subtitle-1 ma-0 text--black">{{shipment.baspLoctionName }}</p>
            <p class="body-2 ma-0 grey--text">{{shipment.baspLocationAddress }}</p>
          </div>
        </div>
      </v-col>
      <v-col cols="auto">
        <v-btn @click="baspDetail" color="primary" text>
          {{$_strings.statusOrder.VIEW}}
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="12" sm="6">
            <p class="body-2 ma-0 grey--text">{{$_strings.statusOrder.BASP_NOTE}}</p>
            <pre class="overflow-auto"><p class="body">{{shipment.baspNote ? shipment.baspNote : '-'}}</p></pre>
          </v-col>
          <v-col cols="12" sm="6">
            <p class="body-2 ma-0 grey--text">{{$_strings.statusOrder.BASP_TITLE}}</p>
            <p class="body">{{shipment.baspTitle ? shipment.baspTitle : '-'}}</p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <basp ref="dialogBasp" />
  </section>
</template>

<script>
import Basp from '../Dialog/Basp.vue';

export default {
  props: {
    shipment: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    Basp,
  },
  methods: {
    async baspDetail() {
      try {
        const { id } = this.$route.params;
        this.$root.$loading.show();
        const result = await this.$_services.order.baspDetail(id);
        this.$refs.dialogBasp.items = result.data;
        this.$refs.dialogBasp.dialog = true;
      } finally {
        this.$root.$loading.hide();
      }
    },
  },
};
</script>
