<template>
  <v-row>
    <v-col cols="12" class="pb-0">
      <h4 class="mb-0">{{$_strings.order.LOCATION_MAP}}</h4>
    </v-col>
    <v-col>
      <GmapMap
        :center='center'
        :zoom='5'
        map-type-id="terrain"
        style='width:100%;  height: 400px;'
      >
        <section>
          <!-- lokasi asal -->
          <GmapMarker
            v-if="shipment.origin && shipment.origin.latitude"
            :position="{
              lat: shipment.origin.latitude,
              lng: shipment.origin.longitude
            }"
            :clickable="true"
            :icon="{url : require('@/assets/images/map-marker-origin.png')}"
          />
          <!-- lokasi tujuan-->
          <GmapMarker
            v-if="shipment.destination && shipment.destination.latitude"
            :position="{
              lat: shipment.destination.latitude,
              lng: shipment.destination.longitude
            }"
            :clickable="true"
            :icon="{url : require('@/assets/images/map-marker-destination.png')}"
          />
          <!-- lokasi driver -->
          <section v-if="shipment.driverLatitude && shipment.driverLongitude">
            <GmapMarker
              :position="{
                lat: shipment.driverLatitude,
                lng: shipment.driverLongitude
              }"
              :clickable="true"
              :icon="{url : require('@/assets/images/yellow-truck-map-marker-icon.png')}"
            />
          </section>
          <!-- multipick -->
          <section v-for="(multiPick) in shipment.multiPick" :key="multiPick.latitude">
            <GmapMarker
              :position="{
                lat: multiPick.latitude,
                lng: multiPick.longitude
              }"
              :clickable="true"
              :icon="{url : require('@/assets/images/map-marker-multipick.png')}"
            />
          </section>
          <!-- multidrop -->
          <section v-for="(multiDrop) in shipment.multiDrop" :key="multiDrop.latitude">
            <GmapMarker
              :position="{
                lat: multiDrop.latitude,
                lng: multiDrop.longitude
              }"
              :clickable="true"
              :icon="{url : require('@/assets/images/map-marker-multidrop.png')}"
            />
          </section>
        </section>
      </GmapMap>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    shipment: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    this.geolocate();
  },
  data() {
    return {
      center: {
        lat: -6.1753917,
        lng: 106.82715329999996,
      },
    };
  },
  methods: {
    geolocate() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      });
    },
  },
};
</script>
