<template>
  <v-container fluid>
    <v-card  class="pa-2">
      <section v-if="isLoading" class="mt-4">
        <v-skeleton-loader
          class="mx-auto"
          type="heading"
        ></v-skeleton-loader>
        <v-skeleton-loader
          class="mx-auto"
          type="list-item-two-line, card, list-item-two-line"
        ></v-skeleton-loader>
      </section>
      <section v-else-if="isError">
        <v-row class="mt-5">
          <v-col cols="12">
            <h1 class="text-center grey--text">{{$_strings.common.ERROR_MESSAGE}}</h1>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-icon large @click="fetchData">
              mdi-reload
            </v-icon>
          </v-col>
        </v-row>
      </section>
      <section v-else>
        <shipment-parent :item="shipmentParent" />
        <route-info-table :items="routeItems" />
        <section class="px-2">
          <v-row v-if="shipmentDetail.baspLoctionName" class="mt-4">
            <v-col cols="12">
              <basp-location
                :shipment="shipmentDetail"
              />
              <v-divider class="mt-8"></v-divider>
            </v-col>
          </v-row>
          <v-row class="mt-4">
            <v-col cols="12" sm="6">
              <location-details
                :shipmentItem="shipmentDetail.origin || {}"
                :shipmentsDetails="multiPick"
                :title="$_strings.statusOrder.ORIGIN"
                :picNameTitle="$_strings.statusOrder.NAME_SENDER"
                :picPhoneTitle="$_strings.statusOrder.PHONE_NUMBER_SENDER"
                :picNoteTitle="$_strings.statusOrder.SENDER_NOTE"
                type="ORIGIN"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <location-details
                :shipmentItem="shipmentDetail.destination || {}"
                :shipmentsDetails="multiDrop"
                :title="$_strings.statusOrder.DESTINATION"
                :picNameTitle="$_strings.statusOrder.RECEPIENTS_NAME"
                :picPhoneTitle="$_strings.statusOrder.PHONE_NO_RECIPIENT"
                :picNoteTitle="$_strings.statusOrder.RECIPIENT_NOTE"
                type="DESTINATION"
              />
            </v-col>
          </v-row>
          <shipment-category :shipment="shipmentDetail"/>
          <v-row v-if="shipmentDetail.serviceType === 'LCL'">
            <v-col cols="12">
              <v-divider class="my-4"></v-divider>
              <units-information
                :shipment="shipmentDetail"
              ></units-information>
            </v-col>
          </v-row>
        </section>
        <v-row class="mt-2">
          <v-col cols="12" sm="4">
            <order-status :historyStatus="shipmentStatus" />
          </v-col>
          <v-col cols="12" sm="8">
            <v-card class="pa-4">
              <documents :documents="shipmentDocument"/>
              <v-divider class="mt-6 mb-2"></v-divider>
              <Map :shipment="itemMap" />
            </v-card>
          </v-col>
        </v-row>
        <btn-actions :shipment="{...shipmentParent, ...shipmentDetail}" />
        <v-divider class="mt-8"></v-divider>
        <footer-order-detail />
      </section>
    </v-card>
  </v-container>
</template>

<script>

import axios from 'axios';
import dayjs from 'dayjs';
import ShipmentParent from './ShipmentParent.vue';
import RouteInfoTable from './RouteInfoTable.vue';
import OrderStatus from './OrderStatus.vue';
import Documents from './Documents.vue';
import Map from './Map.vue';
import LocationDetails from './LocationDetails.vue';
import FooterOrderDetail from './FooterOrderDetail.vue';
import UnitsInformation from './UnitInformation.vue';
import BaspLocation from './BaspLocation.vue';
import BtnActions from './BtnActions.vue';
import ShipmentCategory from './ShipmentCategory.vue';

const { CancelToken } = axios;
let source = CancelToken.source();
export default {
  created() {
    source = CancelToken.source();
    this.fetchData();
  },
  components: {
    ShipmentParent,
    RouteInfoTable,
    OrderStatus,
    Documents,
    Map,
    LocationDetails,
    FooterOrderDetail,
    UnitsInformation,
    BtnActions,
    BaspLocation,
    ShipmentCategory,
  },
  data() {
    const { status } = this.$route.query;
    return {
      isLoading: false,
      isError: false,
      isBasp: status === 'NEED_CONFIRMATION',
      shipmentParent: {},
      shipmentDetail: {},
      shipmentDocument: [],
      shipmentStatus: [],
    };
  },
  computed: {
    multiPick() {
      return this.shipmentDetail.shipmentsDetail ? this.shipmentDetail.shipmentsDetail.filter((s) => s.multiType === 'MULTIPICK') : [];
    },
    multiDrop() {
      return this.shipmentDetail.shipmentsDetail ? this.shipmentDetail.shipmentsDetail.filter((s) => s.multiType === 'MULTIDROP') : [];
    },
    routeItems() {
      return [{
        originLocationName: this.shipmentParent.originLocationName,
        destinationLocationName: this.shipmentParent.destinationLocationName,
        multiPick: this.multiPick,
        multiDrop: this.multiDrop,
        transportName: this.shipmentDetail.transportTypes?.name || '-',
        driverName: this.shipmentDetail.driverName || '-',
        driverPhone: this.shipmentDetail.driverPhone || '-',
        licensePlate: this.shipmentDetail.licensePlate || '-',
        transportModa: this.shipmentDetail.transportTypes?.transportModa || '-',
        etaDateTime: this.shipmentParent.etaDateTime,
        latestStatus: this.shipmentParent.latestStatus,
      }];
    },
    itemMap() {
      return {
        origin: this.shipmentDetail.origin,
        destination: this.shipmentDetail.destination,
        multiPick: this.multiPick,
        multiDrop: this.multiDrop,
      };
    },
  },
  methods: {
    async fetchData() {
      const {
        pickupDateTo,
        pickupDateFrom,
        status,
        title,
      } = this.$route.query;
      const filters = {
        page: 0,
        size: 10,
        pickupDateFrom: dayjs(pickupDateFrom).format('YYYY-MM-DD'),
        pickupDateTo: dayjs(pickupDateTo).format('YYYY-MM-DD'),
      };
      try {
        this.isError = false;
        this.isLoading = true;
        const { id: shipmentCargoId } = this.$route.params;
        const result = await this.$_services.order.getOrderDetail(filters, status, title, shipmentCargoId, this.isBasp, source);
        this.shipmentParent = result[0].data.contents.find((s) => s.id === +shipmentCargoId) || {};
        this.shipmentDetail = result[1].data;
        this.shipmentStatus = result[2].data;
        this.shipmentDocument = result[3].data;
      } catch {
        this.isError = true;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
