<template>
  <footer>
    <v-container fluid>
      <v-row
        justify-sm="end"
      >
        <v-col cols="12" sm="auto" md="2" class="mr-0">
          <v-btn
            color="primary"
            block
            outlined
            @click="$router.go(-1)"
          >
            {{$_strings.common.BACK}}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </footer>
</template>

<script>

export default {
  methods: {
  },
};
</script>
