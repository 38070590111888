<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="600"
    scrollable
  >
    <v-card :loading="isLoading">
      <v-card-title class="caption">
        <h3>{{$_strings.order.LABEL_CANCEL_ORDER}}</h3>
        <v-row>
          <v-col class="d-flex pa-0 ma-0 justify-end">
            <v-toolbar-items>
              <v-btn
                icon
                color="black"
                dark
                @click="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="mt-4">
        <v-row>
          <v-col class="pb-0">
            <common-auto-complete-items
              v-if="!showInputReason"
              type="reasonCancelOrder"
              searchName="name"
              item-text="reason"
              item-value="reason"
              dense
              clearable
              outlined
              v-model="form.notes"
              :rules="[v => !!v || $_strings.messages.validation.REQUIRED('Alasan pembatalan')]"
              placeholder="Pilih alasan pembatalan"
              :items="reasonList"
              :filter="filterReasonList"
              @updateItems="updateReasonList"
            />
            <v-checkbox
              v-model="showInputReason"
              @change="form.notes = ''"
              label="Lainnya"
            ></v-checkbox>
            <v-text-field
              v-if="showInputReason"
              dense
              outlined
              :rules="[v => !!v || $_strings.messages.validation.REQUIRED('Alasan pembatalan')]"
              v-model="form.notes"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="red"
          class="white--text"
          @click="dialog = false"
        >
          {{$_strings.common.CLOSE}}
        </v-btn>
        <v-btn
          color="primary"
          @click="cancelOrder"
          :disabled="!form.notes && !inputReason"
          :loading="isLoading"
        >
          {{$_strings.common.YES}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { getDateTimeTz } from '@/helper/commonHelpers';

export default {
  data() {
    return {
      dialog: false,
      isLoading: false,
      inputReason: '',
      showInputReason: false,
      reasonList: [],
      filterReasonList: {
        page: 0,
        size: 25,
        totalData: 0,
      },
      form: {
        status: 'DIBATALKAN',
        notes: '',
        statusDatetimeTz: getDateTimeTz(),
        datetimeTz: getDateTimeTz(),
      },
    };
  },
  methods: {
    updateReasonList(newItems) {
      this.reasonList = [...this.reasonList, ...newItems];
    },
    async cancelOrder() {
      const { id } = this.$route.params;
      this.isLoading = true;
      try {
        await this.$_services.order.cancelOrder(id, this.form);
        this.$dialog.notify.success('Berhasil membatalkan pesanan');
        this.$router.replace('/main/status-pesanan/list/completed');
      } finally {
        this.isLoading = false;
      }
    },

  },
};
</script>
