<template>
  <v-card>
    <v-card-title>
      <h5>{{$_strings.statusOrder.STATUS_ORDER}}</h5>
    </v-card-title>
    <v-card-text>
      <section style="margin-left:-40px">
        <v-timeline v-for="(orderHistories, index) in historyStatus" dense align-top :key="index">
          <v-timeline-item color="blue lighten-2" fill-dot small right>
            <v-row>
              <v-col class="ma-0 pa-0">
                <p class="grey--text mb-0">
                  {{dateFormat(orderHistories.statusDateTime)}}
                </p>
                <p class="caption black--text mb-0">{{orderHistories.status}}</p>
                <p class="caption grey--text">
                  {{getNotes(orderHistories.status, orderHistories.notes)}}
                </p>
              </v-col>
              <v-col cols="auto" class="grey--text">{{timeFormat(orderHistories.statusDateTime)}}</v-col>
            </v-row>
          </v-timeline-item>
        </v-timeline>
      </section>
    </v-card-text>
  </v-card>
</template>

<script>
import { dateFormat, timeFormat } from '@/helper/commonHelpers';

export default {
  props: {
    historyStatus: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    dateFormat,
    timeFormat,
    getNotes(status, notes) {
      if (status === 'BA Penerimaan - Diterima Gudang') {
        let locationName = '';
        const lastLocation = this.historyStatus.find((history) => history.status === 'Kedaluwarsa');
        if (lastLocation) locationName = lastLocation.notes;
        return locationName;
      }
      return notes;
    },
  },
};
</script>
