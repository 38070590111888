<template>
  <v-row>
    <v-col cols="12" sm="6">
      <v-divider class="my-8"></v-divider>
      <h5>Kategori Barang</h5>
      <p class="mt-2 mb-0">{{shipment.categoryName || '-'}}</p>
      <p class="text-caption grey--text">{{shipment.subCategoryName || '-'}}</p>
      <v-row>
        <v-col cols="12">
          <h5>Informasi Tambahan</h5>
          <p class="text-body-2">{{shipment.goodsNote || '-'}}</p>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" sm="6">
      <v-divider class="my-8"></v-divider>
      <h5>Catatan Tambahan</h5>
      <p class="mt-2 mb-0 caption">{{shipment.additionalNote || '-'}}</p>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    shipment: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
