<template>
  <v-card outlined>
    <v-card-actions class="grey lighten-5">
      <v-row>
        <v-col class="d-flex align-center pl-0 ml-2">
          <h4>{{$route.query.title}}</h4>
        </v-col>
        <v-col cols="12" sm="8" class="d-flex justify-space-between justify-sm-end pb-2">
          <ButtonsUploadOrder
            v-if="isShowButtonUpload(item)"
            :shipment="item"
            @fetchData="$emit('fetchData')"
          />
          <v-chip
            :color="chipColor(item.latestStatus).color"
            :class="chipColor(item.latestStatus).class"
            label
          >
            {{item.latestStatus}}
          </v-chip>
          <v-btn
            icon
            @click="showShipmentExpand = !showShipmentExpand"
          >
            <v-icon>{{ showShipmentExpand ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
    <v-expand-transition>
      <div v-show="showShipmentExpand">
        <v-divider></v-divider>
        <v-card-text>
          <span class="grey--text">
            {{$_strings.order.DELIVERY_DATE}} : {{dateFormat(item.pickupDate)}}
          </span>
          <v-card class="mt-2">
            <v-card-text>
              <v-row>
                <v-col v-for="(item, index) in items" :key="index" cols="12" sm="6">
                  <label class="mb-0 mt-2 grey--text">{{item.label}}</label>
                  <v-text-field
                    outlined
                    hide-details
                    dense
                    class="caption"
                    :value="item.value"
                    disabled>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
import { dateFormat } from '@/helper/commonHelpers';

const ButtonsUploadOrder = () => import(/* webpackChunkName: "DialogUploadOrder" */ './BtnUploadOrder.vue');

export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    ButtonsUploadOrder,
  },
  data() {
    return {
      showShipmentExpand: true,
    };
  },
  computed: {
    items() {
      return [
        {
          label: this.$_strings.order.ORIGIN,
          value: this.item.originLocationName,
        },
        {
          label: this.$_strings.order.DESTINATION,
          value: this.item.destinationLocationName,
        },
        {
          label: this.$_strings.order.TYPE_MODA,
          value: this.item.serviceType,
        },
      ];
    },
  },
  methods: {
    dateFormat,
    isShowButtonUpload(item) {
      const { latestStatus } = item;
      const canUpload = this.menuFunctions.UPLOAD_DETAIL_BARANG;
      const orderCompleted = [
        'bap - konfirmasi shipper',
        'barang diterima utuh',
        'kedaluwarsa',
        'waktu habis',
        'dibatalkan',
      ];
      return canUpload && !orderCompleted.includes(latestStatus.toLowerCase());
    },
    chipColor(status = '') {
      switch (status.toLowerCase()) {
        case 'waktu habis':
        case 'kedaluwarsa':
        case 'dibatalkan': return {
          color: 'red',
          class: 'white--text',
        };
        default: return {
          color: 'cyan lighten-5',
          class: 'cyan--text',
        };
      }
    },
  },
};
</script>
